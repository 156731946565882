<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            FAQ
          </v-card-title>
          <v-card-subtitle>
            Hier erhalten Sie Antworten auf häufig gestellte Fragen.
          </v-card-subtitle>
          <v-list>
            <v-list-item-group
              color="primary"
            >
              <v-list-item
                v-if="partner_mh"
                href="https://docs.google.com/document/d/10JdNNRhD-MrclzZDLInenqfDMJjvzyfJgCxum8jV0xY/edit?usp=sharing"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon>open_in_new</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    MeinHilfsmittel.de FAQ
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="partner_me"
                href="https://docs.google.com/document/d/1gNwVw_J_gmCarrdHCqFSc2AolZLoewvpYfY0cpkusnM/edit?usp=sharing"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon>open_in_new</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    MeinElektromobil.de FAQ
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            Updates
          </v-card-title>
          <v-card-subtitle>
            Hier erfahren Sie, welche Änderungen es in der letzten Zeit gab.
          </v-card-subtitle>
          <v-list>
            <v-list-item-group
              color="primary"
            >
              <v-list-item
                v-if="partner_mh"
                href="https://docs.google.com/document/d/1ROnpLpOtXcolVuA2HyYTjLWFJ0Orw5ebx7dySNKw6LY/edit?usp=sharing"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon>open_in_new</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    MeinHilfsmittel.de Updates
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="partner_me"
                href="https://docs.google.com/document/d/1Jo69DZ3jceBp9bTvOQralSW88nWUH04IBJ7z9OveOPY/edit?usp=sharing"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon>open_in_new</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    MeinElektromobil.de Updates
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      partner_me: 'auth/partner_me',
      partner_mh: 'auth/partner_mh',
    }),
  },
}
</script>
